import ReactDatePicker from 'react-datepicker'
import styled, { css } from 'styled-components'

import { Variant } from '../../../themes'
import { inputStyle } from '../Input/Input.styles'

type DatepickerProps = {
  disabled: boolean
  withPortal?: boolean
  filled?: boolean
  fluid?: boolean
}

export const DatepickerInput = styled(ReactDatePicker)<{
  disabled: boolean
  filled: boolean
  fluid?: boolean
  variant?: Variant
}>`
  &&&& {
    ${inputStyle}
    padding-right: 40px;
  }

  &&&&::placeholder {
    white-space: pre-line;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-right: 40px;
  }

  width: ${props => {
    if (props.fluid) return '100%'
    if (props.selectsRange) return '220px'
    return '140px'
  }};
`

export const IconContainer = styled.div<DatepickerProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${props => props.theme.dimensions.inputHeight};
  width: ${props => props.theme.dimensions.inputHeight};
  display: flex;
  margin: 0;
  color: ${props => props.theme.colors.secondary.light};
  opacity: ${props => (props.disabled ? props.theme.opacities.disabled : 1)};

  &:before {
    align-self: center;
    margin: 0 auto;
  }

  & > i,
  & > svg {
    display: flex;
    align-self: center;
    margin: 0 auto;
  }
`

const datepickerStyle = css<DatepickerProps>`
  .react-datepicker {
    font-size: ${props => props.theme.fontSizes.small};
    border-color: ${props => props.theme.colors.neutral.base};
    border-radius: ${props => props.theme.dimensions.borderRadius};
    box-shadow: ${props => props.theme.shadows.low};

    &__header {
      background-color: ${props => props.theme.colors.secondary.lightest};
      border-bottom: none;
    }

    &__day,
    &__day-name {
      ${props =>
        !props.withPortal &&
        `
        width: 2.15rem;
        line-height: 2.15rem;
      `}

      &.react-datepicker__day--disabled.react-datepicker__day--in-range {
        background-color: ${props => props.theme.colors.primary.base};
      }

      &.react-datepicker__day--highlighted-danger {
        color: ${props => props.theme.colors.danger.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }
      &.react-datepicker__day--highlighted-neutral {
        color: ${props => props.theme.colors.neutral.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }
      &.react-datepicker__day--highlighted-primary {
        color: ${props => props.theme.colors.primary.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }
      &.react-datepicker__day--highlighted-secondary {
        color: ${props => props.theme.colors.secondary.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }
      &.react-datepicker__day--highlighted-success {
        color: ${props => props.theme.colors.success.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }
      &.react-datepicker__day--highlighted-warning {
        color: ${props => props.theme.colors.warning.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }

      &:hover,
      &--keyboard-selected:hover {
        background-color: ${props => props.theme.colors.secondary.lightest};
        border-radius: ${props => props.theme.dimensions.borderRadius};
      }

      &--selected,
      &--keyboard-selected {
        color: ${props => props.theme.colors.white.base} !important;
        background-color: ${props => props.theme.colors.primary.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};

        &:hover {
          background-color: ${props => props.theme.colors.primary.base};
        }
      }
    }

    &__day {
      &--in-selecting-range:hover {
        background-color: ${props => props.theme.colors.primary.base};
      }

      &--in-range {
        color: ${props => props.theme.colors.white.base} !important;
        background-color: ${props => props.theme.colors.primary.base};
        border-radius: ${props => props.theme.dimensions.borderRadius};

        &:hover {
          background-color: ${props => props.theme.colors.primary.base};
        }
      }
    }
  }
`

export const DatepickerContainer = styled.div<DatepickerProps>`
  display: inline-block;
  position: relative;
  ${props => props.fluid && 'width: 100%'};

  .react-datepicker__close-icon::after {
    background-color: transparent;
    color: ${props => props.theme.colors.neutral.base};
    font-size: 24px;
    padding-right: 7px;
    padding-top: 3px;
  }
`

export const CalendarContainer = styled.div<DatepickerProps>`
  .react-datepicker-wrapper {
    display: block;
  }

  ${datepickerStyle}
`

export const DatepickerAndIconWrapper = styled.div`
  display: inline-block;
  position: relative;
`
