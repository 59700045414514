import styled from 'styled-components'

export const CheckboxInput = styled.input.attrs(() => ({ type: 'checkbox' }))`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

export const ToggleContainer = styled('div')<{
  disabled: boolean
  checked: boolean
}>`
  display: inline-flex;
  & > label {
    align-content: center;
    box-sizing: border-box;
    background-color: white;
    user-select: none;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    height: ${props => props.theme.dimensions.inputHeight};
    padding: ${props => props.theme.spacings.small}
      calc(1.5 * ${props => props.theme.spacings.small});
    font-family: ${props => props.theme.fontFamilies};
    font-size: ${props => props.theme.fontSizes.medium};
    line-height: ${props => props.theme.fontSizes.medium};
    border-radius: ${props => props.theme.dimensions.borderRadius};
    border: 1px solid ${props => props.theme.colors.neutral.base};

    &:hover,
    &:focus {
      border-color: ${props => props.theme.colors.primary.base};
    }

    ${props =>
      props.checked &&
      `
      border-color: ${props.theme.colors.primary.base};
      box-shadow: inset 0 0 0 1px ${props.theme.colors.primary.base};
      background-color: ${props.theme.colors.primary.lighter};
      `};

    ${props =>
      props.disabled &&
      `
          opacity: ${props.theme.opacities.disabled};
          border-color: ${props.theme.colors.secondary.lighter};
          box-shadow: none;
          &:hover {
            border-color: ${props.theme.colors.secondary.lighter};
          }
        `}
  }
`
