import React, { memo } from 'react'

import { Width } from '../../../themes'
import { Infotip } from '../../core'
import { Inline } from '../../layout'
import { StyledLabel, StyledText } from './Label.styles'

export interface LabelProps {
  'data-e2e'?: string
  className?: string
  ellipsis?: boolean
  helpText?: string
  htmlFor?: string
  noWrap?: boolean
  onClick?: React.MouseEventHandler<HTMLLabelElement>
  required?: boolean
  text: string
  width?: Width
}

const Label = ({
  'data-e2e': dataE2e,
  className,
  ellipsis,
  helpText,
  htmlFor,
  noWrap,
  onClick,
  required = false,
  text,
  width,
}: LabelProps) => (
  <StyledLabel htmlFor={htmlFor} onClick={onClick} className={className}>
    <Inline alignment="baseline" spacing="xsmall">
      <StyledText
        data-e2e={dataE2e}
        ellipsis={ellipsis}
        fontCase="uppercase"
        noWrap={noWrap}
        required={required}
        size="small"
        width={width}
      >
        {text}
      </StyledText>

      {!!helpText && (
        <Infotip data-e2e={dataE2e ? `${dataE2e}--helpText` : undefined} helpText={helpText} />
      )}
    </Inline>
  </StyledLabel>
)

export default memo(Label)
