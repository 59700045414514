import { BaseTheme } from 'styled-components'

const menuTheme = {
  colors: {
    primary: 'rgb(33, 133, 208)',
    secondary: 'rgb(18, 73, 114)',
    success: 'rgb(30, 167, 62)',
    danger: 'rgb(219, 40, 40)',
    neutral: 'rgb(144, 163, 179)',
    warning: 'rgb(251, 189, 8)',
    pending: 'rgb(242, 113, 28)',
    black: 'rgb(0, 0, 0)',
    white: 'rgb(255, 255, 255)',
    foreground: 'rgb(101, 114, 125)',
    background: 'rgb(255,255,255)',
  },
  dimensions: {
    desktop: {
      footerHeight: '65px',
      headerHeight: '65px',
      width: '220px',
    },
    mobile: {
      topBarHeight: '60px',
    },
  },
}

const procsea: BaseTheme = {
  colors: {
    primary: 'rgb(33, 133, 208)',
    secondary: 'rgb(18, 73, 114)',
    success: 'rgb(30, 167, 62)',
    danger: 'rgb(219, 40, 40)',
    neutral: 'rgb(144, 163, 179)',
    warning: 'rgb(251, 189, 8)',
    pending: 'rgb(242, 113, 28)',
    black: 'rgb(0, 0, 0)',
    white: 'rgb(255, 255, 255)',
    foreground: 'rgb(101, 114, 125)',
    background: 'rgb(255,255,255)',
  },
  fontFamilies: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
  fontWeights: {
    light: 100,
    regular: 400,
    bold: 'bold',
  },
  fontSizes: {
    xsmall: '0.7143rem',
    small: '0.8571rem',
    medium: '1rem',
    large: '1.1429rem',
    xlarge: '1.2857rem',
    xxlarge: '1.5714rem',
    xxxlarge: '1.7143rem',
  },
  spacings: {
    xxsmall: `${2 / 14}rem`,
    xsmall: `${4 / 14}rem`,
    small: `${7 / 14}rem`,
    medium: '1rem',
    large: `${21 / 14}rem`,
    xlarge: `${28 / 14}rem`,
  },
  opacities: {
    disabled: '0.45',
  },
  shadows: {
    low: '1px 2px 4px rgba(50, 50, 50, 0.1)',
    middle: '1px 3px 14px rgba(50, 50, 50, 0.1)',
    high: '1px 20px 30px rgba(50, 50, 50, 0.1)',
  },
  dimensions: {
    mobileHeaderHeight: '40px',
    borderRadius: '4px',
    inputHeight: '40px',
  },
  breakpoints: {
    mobile: {
      threshold: 0,
      container: '100%',
    },
    tablet: {
      threshold: 768,
      container: '100%',
    },
    smallDesktop: {
      threshold: 1025,
      container: '100%',
    },
    mediumDesktop: {
      threshold: 1336,
      container: '1144px',
    },
    largeDesktop: {
      threshold: 1600,
      container: '1378px',
    },
  },
  qualities: {
    'E+': 'rgb(30, 167, 62)',
    E: 'rgb(75, 185, 101)',
    'A+': 'rgb(181, 204, 24)',
    A: 'rgb(251, 189, 8)',
    B: 'rgb(242, 113, 28)',
  },
  styles: {
    base: {
      fontSize: '14px',
      background: 'rgb(255, 255, 255)',
      color: 'rgb(7, 28, 44)',
    },
    light: {
      fontSize: '14px',
      background: 'rgb(243, 246, 248)',
      color: 'rgb(7, 28, 44)',
    },
  },
  transitions: {
    duration: 500,
  },
  menu: menuTheme,
}

export default procsea
