import { compact, without } from 'lodash'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import { Inline, Stack } from '../../layout'
import { CheckboxFormatType } from '../Checkbox/Checkbox'
import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import { FormComponent, FormOnChange } from '../interfaces'
import { CheckboxGroupContainer, InlineContainer } from './CheckboxGroup.styles'

export interface CheckboxGroupProps extends FormComponent<string[]> {
  children?: ReactNode
  format?: CheckboxFormatType
  inlined?: boolean
  noWrap?: boolean
}

const CheckboxGroup = ({
  'data-e2e': dataE2e,
  children,
  className,
  disabled = false,
  errorMessage,
  format = 'default',
  helpText,
  inlined = false,
  label,
  name,
  noWrap = false,
  onChange,
  required = false,
  value = [],
  warningMessage,
}: CheckboxGroupProps) => {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [JSON.stringify(value)])

  const handleChange: FormOnChange<string> = useCallback(
    data => {
      const newValues = data.value
        ? compact([...selectedValue, data.name])
        : compact(without(selectedValue, data.name))

      setSelectedValue(newValues)
      onChange?.({ name, value: newValues })
    },
    [name, onChange, selectedValue]
  )

  const childrenWithProps = React.Children.map(
    children as React.ReactElement,
    (child: React.ReactElement) =>
      React.cloneElement(child, {
        checked: selectedValue.includes(child.props.name),
        format,
        disabled,
        hasError: !!errorMessage,
        hasWarning: !!warningMessage,
        onChange: handleChange,
      })
  )

  return (
    <CheckboxGroupContainer className={className} data-e2e={dataE2e}>
      {label && <Label text={label} helpText={helpText} required={required} />}

      {inlined || format === 'toggled' ? (
        <InlineContainer data-e2e="inline-container" noWrap={noWrap}>
          <Inline alignment="center" spacing="small" inline noWrap={noWrap}>
            {childrenWithProps}
          </Inline>
        </InlineContainer>
      ) : (
        <Stack spacing="xsmall" inline>
          {childrenWithProps}
        </Stack>
      )}

      {!!errorMessage && (
        <Message data-e2e="checkbox-group-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="checkbox-group-warning-message" type="warning" value={warningMessage} />
      )}
    </CheckboxGroupContainer>
  )
}

export default CheckboxGroup
