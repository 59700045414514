import { tint } from 'polished'
import styled, { css } from 'styled-components'

type Props = {
  isValid: boolean
  hasError: boolean
  isFocus: boolean
  hasSubLabel: boolean
  disabled: boolean
}

const InputNumberButton = styled.button.attrs(() => ({ type: 'button' }))`
  outline: none;
  border: none;
  appearance: none;
  padding: ${props => `${props.theme.spacingsInsetSquish.small}`};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  height: ${props => props.theme.dimensions.inputHeight};
  width: 40px;
  color: black;
  line-height: 1rem;
  font-weight: ${props => props.theme.fontWeights.bold};
  background-color: ${props => props.theme.colors.secondary.lighter};
  margin: 0;
  border: 1px solid ${props => props.theme.colors.neutral.base};
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${props => tint(0.8, props.theme.colors.secondary.base)};
    border: 1px solid ${props => props.theme.colors.neutral.base} !important;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: ${props => props.theme.opacities.disabled};

    &:hover {
      background-color: ${props => props.theme.colors.secondary.lighter};
    }
  }
`

export const InputNumberButtonLeft = styled(InputNumberButton)`
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:hover,
  &:focus {
    border-right: none !important;
  }
`

export const InputNumberButtonRight = styled(InputNumberButton)`
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover,
  &:focus {
    border-left: none !important;
  }
`

export const InputNumber = styled.input.attrs(() => ({ type: 'number' }))`
  width: 65px;
  border: none;
  border-radius: 0;
  text-align: center;
  box-sizing: border-box;
  -moz-appearance: textfield;

  &:disabled {
    cursor: not-allowed;
    background-color: white;
    opacity: ${props => props.theme.opacities.disabled};
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:invalid,
  &:-moz-submit-invalid,
  &:-moz-ui-invalid {
    box-shadow: none;
  }
`

export const InputNumberLabel = styled.div`
  height: 18px;
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.neutral.base};
  background-color: white;
  text-align: center;
  cursor: text;
  line-height: 16px;
`

export const InputNumberContainer = styled.div<Props>`
  background-color: white;
  border: 1px solid
    ${props => {
      if (props.hasError) {
        return props.theme.colors.danger.base
      }
      if (props.isValid) {
        return props.isFocus ? props.theme.colors.primary.base : props.theme.colors.neutral.base
      }
      return props.theme.colors.danger.base
    }};

  &:hover {
    border: 1px solid
      ${props =>
        props.isValid && !props.hasError
          ? props.theme.colors.primary.base
          : props.theme.colors.danger};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: ${nestedProps => nestedProps.theme.opacities.disabled};

      &:hover {
        border: 1px solid ${nestedProps => nestedProps.theme.colors.neutral.base};
      }
    `}

  ${InputNumber} {
    height: ${props => (props.hasSubLabel ? '20px' : '36px')};
    color: ${props =>
      props.isValid && !props.hasError ? 'black' : props.theme.colors.danger.base};
  }
`

export const InputNumberWrapper = styled.div`
  display: inline-flex;
`

export const StyledInputNumberSelector = styled.div`
  position: relative;
  display: inline-block;
`
