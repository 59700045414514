import styled from 'styled-components'

import { Text } from '../../core'

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: ${props => props.theme.spacings.xsmall};
  color: ${props => props.theme.colors.neutral.darker};
`

export const StyledText = styled(Text)<{ required?: boolean }>`
  ${props =>
    props.required &&
    `
  &:after{
    content: '*';
    color: ${props.theme.colors.danger.base};
    font-size: 1.2rem;
    vertical-align: bottom;
  }
  `}
`
