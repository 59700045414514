import Select from 'react-select'
import styled, { css } from 'styled-components'

import { Variant } from '../../../themes'
import { Stack } from '../../layout'
import { inputStyle } from '../Input/Input.styles'

export interface StyledSelectProps {
  disabled: boolean
  filled: boolean
  inline: boolean
  isSearchable: boolean
  rightPosition: boolean
  variant?: Variant
  wrapOptions?: boolean
}

export const mixinSelectStyle = css<StyledSelectProps>`
  & .Select__control {
    cursor: pointer;
    ${inputStyle}
    padding: 0 ${props => props.theme.spacings.medium};
    ${props =>
      props.inline &&
      ` border: none;
      box-shadow: none;
      height: auto;
      min-height: inherit;
      background-color: inherit;
      padding: 0 ${props.theme.spacings.small};
    `}
  }

  & .Select__control--is-focused {
    box-shadow: none;
    ${props =>
      !props.inline && `border: 1px solid ${props.theme.colors[props.variant || 'primary'].base}`};
  }

  & .Select__value-container {
    height: auto;
    min-height: ${props =>
      props.inline ? 'inherit' : `calc(${props.theme.dimensions.inputHeight} - 2px)`};
    position: inherit;
    padding: 0;
  }

  & .Select__value-container--is-multi {
    padding: 0;
    row-gap: ${props => props.theme.spacings.xsmall};
  }

  & .Select__control.Select__control--menu-is-open {
    padding: 0;
    padding: 0 ${props => props.theme.spacings.medium};
    border-radius: ${props => props.theme.dimensions.borderRadius};
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors[props.variant || 'primary'].base};
    border: 1px solid ${props => props.theme.colors[props.variant || 'primary'].base};
    ${props =>
      props.inline &&
      `border: none;
      box-shadow: none;
      height: auto;
      min-height: inherit;
      padding: 0 ${props.theme.spacings.small};
      `}
  }

  & .Select__single-value {
    color: inherit;
    ${props =>
      props.inline &&
      ` max-width: inherit;
      position: initial;
      transform: none;
      color: ${props.theme.colors.primary.base};
      font-weight: ${props.theme.fontWeights.bold};
      text-decoration: underline;
  `}
  }

  & .Select__indicator {
    padding: 8px 0 8px 8px;
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__dropdown-indicator {
    padding: 0 0 0 ${props => props.theme.spacings.small};
    ${props =>
      props.inline &&
      ` color: ${props.theme.colors.primary.base};
  `}
  }

  & .Select__placeholder {
    margin: 0;
    color: ${props => props.theme.colors.neutral.base};
    ${props =>
      props.inline &&
      ` max-width: inherit;
      position: initial;
      transform: none;
      color: ${props.theme.colors.primary.base};
      font-weight: ${props.theme.fontWeights.bold};
      text-decoration: underline;
  `}
  }

  & .Select__multi-value {
    background-color: ${props => props.theme.colors[props.variant || 'secondary'].lighter};
    margin: 0 ${props => props.theme.spacings.xsmall} 0 0;

    &:last-of-type {
      margin: 0;
    }

    & .Select__multi-value__label {
      font-size: inherit;
    }

    & .Select__multi-value__remove:hover {
      background-color: ${props => !props.variant && props.theme.colors.neutral.light};
      color: ${props => props.theme.colors.black.base};
    }
  }
`

export const StyledSelect = styled(Select).attrs<StyledSelectProps>(({ disabled }) => ({
  disabled,
}))<StyledSelectProps>`
  ${mixinSelectStyle}
`

export const StyledSelectContainer = styled('div')<{
  disabled: boolean
  fluid: boolean
  inline: boolean
  minWidth?: string | null
  width?: string
}>`
  ${props => !props.fluid && 'display: inline-block;'}
  ${props => !props.fluid && !props.inline && props.minWidth && `min-width: ${props.minWidth};`}
  ${props => props.disabled && 'cursor: not-allowed;'}
  width: ${props => props.width};
`

export const MainContainer = styled(Stack)<{ fluid: boolean; disabled: boolean }>`
  position: relative;

  ${props =>
    !props.fluid &&
    `
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  `}
`
export const StyledInlineLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.secondary.base};
  vertical-align: top;
`
